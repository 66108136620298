import React from 'react';
import './FooterHeader.css';

class FooterHeader extends React.Component {

  render() {
  
    return (
      <div className="FooterHeader">
     
          <ul>
            <li> Best Subs</li>
          <li>
            <a href="tel:+12129879756">212 - 987 - 9756</a>
            </li>
          </ul>
        </div>

    );
  }
}

export default FooterHeader;
