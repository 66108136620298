import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='Footer-container'>
       Designed and Built by: 
        <a href="https://www.linkedin.com/in/adonis-madera/" target="_blank" rel="noreferrer">Adonis Madera - Call: 917-860-5570
        
       </a>
     
      </div>
    
  );
};

export default Footer;
