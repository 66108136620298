import React, { useEffect } from 'react';
import Beers from '../../assets/images/Beers.png';
import './Hero.css';

const Hero = () => {
  useEffect(() => {
    const phoneNumber = document.querySelector('.phoneNumber');
    const initialPhoneNumberTop = phoneNumber.getBoundingClientRect().top;

    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop >= initialPhoneNumberTop - 1) {
        phoneNumber.classList.add('sticky-banner');
      } else {
        phoneNumber.classList.remove('sticky-banner');
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="phoneNumber">
        <a href="tel:+12129879756">212 - 987 - 9756</a>
      </div>
      <div className="hero">
        <div className="beers-container">
          <img alt="Beers" src={Beers} className="Beers" />
          <img alt="Beers" src={Beers} className="Beers" />
        </div>
        <div className="location-info">
          <div className="address">
            <address>
              <ul>
                <li>1928 3rd Avenue</li>
                <li>Corner of 106th St.</li>
                <li>New York, N.Y. 10029</li>
              </ul>
            </address>
          </div>
          <div className="hours">
            <ul>
              <li>STORE - HOURS :</li>
              <li>MON - FRI : 7AM - 8PM</li>
              <li>SAT : 8AM - 8PM</li>
              <li>SUN : 9AM - 7PM</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
